Bu sayfadaki puf noktalar:
1 - kredi karti sonrasi sunucudan gelen siparis basarili sekilde olustu mu? olusmadi mi? kontrolu icin yonlendirme sayfasi
<template>
    <div class="main">
        <div v-if="normal">yönlendiriliyorsunuz...</div>
        <div v-if="basarili"><i class="fas fa-check-circle"></i><br>İşleminiz başarılı şekilde tamamlandı</div>
        <div v-if="hatali"><i class="fas fa-times-circle"></i><br> Hata oluştu</div>
        <div class="yonlendirme">Ana sayfaya yönlendiriliyorsunuz.. ({{countDown}} saniye)</div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            normal : true,
            basarili : false,
            hatali : false,
            countDown : 3,
        }
    },
    methods : {
        countDownTimer() {
            if(this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            }else if(this.countDown <= 0){
                this.$router.push({name: 'Home'})
            }
        }
    },
    created(){
        this.countDownTimer()
        if(this.$route.params.basarilimi == "ok"){
            this.normal = false
            this.basarili = true
            localStorage.removeItem("sepet")
        }else if(this.$route.params.basarilimi == "error"){
            this.normal = false
            this.hatali = true
        }
    }
}
</script>

<style scoped>
    .main{
        text-align: center;
        height: 1px;
        position: absolute;
        top:-60px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        font-size: 24px;
        font-weight: 800;
        letter-spacing: 2px;
    }
    .main .fa-check-circle{
        font-size: 30px;
        color: rgb(30, 206, 30);
    }
    .main .fa-times-circle{
        font-size: 30px;
        color: rgb(245, 67, 67);
    }
    .main .yonlendirme{
        font-size: 16px;
        font-weight: normal;
        margin-top: 10px;
        letter-spacing: 1px;
    }
</style>